<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <b-card-header>
        <i class="icon-note"></i> Edit Role
        <div class="card-header-actions">
          <a class="card-header-action" href="roles" rel="noreferrer noopener">
            <small class="text-muted">back</small>
          </a>
        </div>
      </b-card-header>
      <b-card-body>
        <div class="loading" v-if="isLoading == true">
            <div class="sk-three-bounce">
                <div class="sk-child sk-bounce1"></div>
                <div class="sk-child sk-bounce2"></div>
                <div class="sk-child sk-bounce3"></div>
            </div>
        </div>
        <b-row>
          <b-col>
            <!--<h6>Simple Form</h6>-->
            <b-form v-on:submit.prevent="onSubmit" v-on:reset.prevent="onReset" novalidate>
              <b-form-group id="roleInputGroup3"
                            label="Name"
                            label-for="role">
                <b-form-input id="role"
                              type="text"
                              v-model.trim="$v.form.role.$model"
                              :state="chkState('role')"
                              aria-describedby="roleFeedback"
                              placeholder="Name"
                              autocomplete='role' />
                <b-form-invalid-feedback id="roleFeedback" >
                  <span v-if="!$v.form.role.required">- Nama role tidak boleh kosong!</span>
                  <span v-if="!$v.form.role.isUnique">- Nama role tidak boleh sama dengan data sebelumnya!</span>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-row>
                <b-col>
                  <p style="font-weight:bold">Permissions</p>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="3" v-for="(permissions, index) in permission" :key="index">
                  <b-form-group>
                    <b-form-checkbox-group id="checkbox-group-2" v-model="permissionName" name="flavour-2">
                      <b-form-checkbox :value="permission[index].id">{{ permission[index].name }}</b-form-checkbox><br>
                    </b-form-checkbox-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-button type="submit" variant="primary">
                Submit
              </b-button>
            </b-form>
            <br/>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required } from "vuelidate/lib/validators"

export default {
  name: "ValidationForms",
  data() {
    return {
      form: {
        role: "",
      },
      permission: "",
      role: '',
      row:[],
      permissionName: [],
      permissions: [],
      selected: "",
      splitSelect: [],
      itemsPerRow: 1,
      errors: {
        message: [],
        status: [],
        code: '',
        headers: [],
        form: {
          role: [],
        },
      },
      isLoading: false
    }
  },
  computed: {
    formStr() { return JSON.stringify(this.form, null, 4) },
    isValid() { return !this.$v.form.$anyError },
    isDirty() { return this.$v.form.$anyDirty },
    invCheck() { return 'You must accept before submitting' },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      role: {
        required,
        isUnique (value) {
          if (value === '') return true

          return new Promise ((resolve, reject) => {
            setTimeout(() => {

              this.$http.post(`role-name/${value}`, {
                roles: this.role
              })
              .then((success) => {
                if (success.data == "") {
                  resolve(true)
                  return true
                }else{
                  reject(false)
                  return false
                }
              })
              .catch((error) => {
                if (error.response) {
                  this.errors.code = error.response.status;
                  this.errors.status = error.response.data.meta.code;
                  this.errors.headers = error.response.headers;
                }
              })
            }, 350 + Math.random() * 300)
          })
        }
      },
    }
  },
  created () {

    this.$http.put(`role-data/` + this.$route.params.id)
    .then((result) => {
      this.form.role = result.data.has_permission.name;
      this.role = this.form.role;
      this.row = result.data.has_permission.permissions.length;
      var rowDetail = [];
      for (let index = 0; index < this.row; index++) {
        rowDetail.push(this.splitSelect = result.data.has_permission.permissions[index].id);
      }
      this.permissionName = rowDetail;
    }).catch((error) => {
      if (error.response) {
        this.errors.code = error.response.data.meta.code;
        this.errors.status = error.response.status;
        this.errors.headers = error.response.headers;
      }
    })
  },
  methods: {
    loadPermission() {

        this.$http.get(`permission-data`, {
            params: {
                permission: this.permissions
            }
        }).then((result) => {
        this.permission = result.data.permission;
        }).catch((error) => {
          if (error.response) {
            this.errors.code = error.response.status;
            this.errors.status = error.response.data.meta.code;
            this.errors.headers = error.response.headers;
          }
        })
    },
    onSubmit() {
    //   if (this.validate()) {
        this.validate()
    //     this.$nextTick(() => {
      this.isLoading = true
            const formData = new FormData();
            formData.append('permission', this.permissionName);
            formData.append('name', this.form.role);

            this.$http.post(`role-update/` + this.$route.params.id , formData
            ).then(() => {
              this.isLoading = false
              this.$router.push("/roles");
              this.$toasted.success('Role successfully updated!')
            }).catch((error) => {
              if (error.response) {
                this.isLoading = false
                this.errors.code = error.response.status;
                this.errors.status = error.response.data.meta.code;
                this.errors.headers = error.response.headers;
                if(this.errors.status == 422) {
                  this.$toasted.error('Please fill out the form that must be required')
                  this.errors.message = error.response.data.meta.message;
                  this.errors.form.role = this.errors.message.role;
                }
              }
            })
    //     })
    //   }
    },
    onReset() {
      // Reset validation
      this.form = Object.assign({},)
      this.submitted = false
      this.$nextTick(() => {
        this.$v.$reset()
        this.feedBack = 'secondary'
      })
    },
    chkState(val) {
      const field = this.$v.form[val]
      return !field.$dirty || !field.$invalid
    },
    findFirstError(component = this) {
      if (component.state === false) {
        if (component.$refs.input) {
          component.$refs.input.focus()
          return true
        }
        if (component.$refs.check) {
          component.$refs.check.focus()
          return true
        }
      }
      let focused = false
      component.$children.some((child) => {
        focused = this.findFirstError(child)
        return focused
      })

      return focused
    },
    validate() {
      this.$v.$touch()
      this.$nextTick(() => this.findFirstError())
      return this.isValid
    }
  },
  mounted () {
      this.loadPermission();
  },
}
</script>

<style scoped>
.btn.disabled {
  cursor: auto;
}
</style>
<style>
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}

</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
